<template>
    <div>
        <TopMenuSubheadline :title="title"/>
        <div v-if="!isLoading">
            <div class="app-content-box bg-gray">
                <div class="container wrapper">
                    <div class="profile-circle m-0 w-100">
                        <div class="profile-picture-wrapper" v-if="!editPhoto">
                            <div class="profile-picture">
                                <img :src="image ? plainUrl + image : require('../assets/avatar-placeholder.jpg')" :alt="fullname">
                            </div>
                        </div>
                        <cropper v-if="editPhoto" ref="cropper"
                                 stencil-component="circle-stencil" :src="editedImage" :stencil-props="{movable: false, scalable: false, handlers: {}}"
                                 @change="onChange($event)"
                        />
                        <div class="profile-name mb-3">
                            <div class="button-wrapper" v-show="!editPhoto">
                                <span class="button" @click="$refs.file.click()">
                                    <input type="file" ref="file" @change="loadImage($event)" accept="image/*;capture=camera">
                                    Zmień zdjęcie profilowe
                                </span>
                            </div>
                        </div>
                        <button v-if="upload" @click="uploadImage($event)" class="btn-black-full w-100 mt-4 mb-2">
                            Zapisz
                        </button>
                    </div>
                </div>
                <div class="form-group form-group-border">
                    <div class="container form-container">
                        <div class="form-edit">
                            <a href="#" class="form-edit-icon" @click="isNameDisable = !isNameDisable"><i
                                    class="fas fa-pencil-alt"></i></a>
                            <input type="text" name="name" class="edit-input" v-model="name" :disabled="isNameDisable"
                                   placeholder="imię"/>
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-border">
                    <div class="container form-container">
                        <div class="form-edit">
                            <a href="#" class="form-edit-icon" @click="isSurnameDisable = !isSurnameDisable"><i
                                    class="fas fa-pencil-alt"></i></a>
                            <input type="text" name="surname" class="edit-input" v-model="surname"
                                   :disabled="isSurnameDisable" placeholder="nazwisko"/>
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-border">
                    <div class="container form-container">
                        <div class="form-edit">
                            <a href="#" class="form-edit-icon" @click="isCityDisable = !isCityDisable"><i
                                    class="fas fa-pencil-alt"></i></a>
                            <input type="text" name="city" class="edit-input" v-model="city" :disabled="isCityDisable"
                                   placeholder="miasto"/>
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-border">
                    <div class="container form-container">
                        <div class="form-edit">
                            <a href="#" class="form-edit-icon" @click="isMottoDisable = !isMottoDisable"><i
                                    class="fas fa-pencil-alt"></i></a>
                            <input type="text" name="motto" class="edit-input" v-model="motto"
                                   :disabled="isMottoDisable" placeholder="motto"/>
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-border">
                    <div class="container form-container">
                        <div class="form-edit">
                            <a href="#" class="form-edit-icon" @click="isEmailDisable = !isEmailDisable">
                                <!-- <i class="fas fa-pencil-alt"></i> -->
                            </a>
                            <input type="email" name="email" class="edit-input" v-model="email"
                                   :disabled="isEmailDisable" placeholder="email"/>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group form-group-border">
                  <div class="container form-container">
                    <div class="form-edit">
                      <a href="#" class="form-edit-icon"><i class="fas fa-pencil-alt"></i></a>
                      <label for="">+48 600 123 456</label>
                    </div>
                  </div>
                </div> -->
                <div class="container mt-2 mb-2"><span class="app-title-text"><b>Zmień hasło</b></span></div>
                <div class="form-group form-group-border">
                    <div class="container form-container">
                        <div class="form-edit">
                            <a href="#" class="form-edit-icon" @click="isPasswordDisable = !isPasswordDisable"><i
                                    class="fas fa-pencil-alt"></i></a>
                            <input type="password" name="password" class="edit-input" v-model="password"
                                   :disabled="isPasswordDisable" placeholder="stare hasło"/>
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-border"  v-if="!isPasswordDisable || (new_password.length || new_password_repeat.length)">
                    <div class="container form-container">
                        <div class="form-edit">
                            <input type="password" name="password" class="edit-input" v-model="new_password"
                                   :disabled="isPasswordDisable" placeholder="nowe hasło"/>
                        </div>
                    </div>
                </div>
                <div class="form-group form-group-border" v-if="!isPasswordDisable || (new_password.length || new_password_repeat.length)">
                    <div class="container form-container">
                        <div class="form-edit">
                            <input type="password" name="password" class="edit-input" v-model="new_password_repeat"
                                   :disabled="isPasswordDisable" placeholder="powtórz nowe hasło"/>
                        </div>
                    </div>
                </div>
                <div class="container submit-wrapper">
                    <div class="mt-4 mb-2" v-if="alertInfo.length">
                        <div class="text-center"><b>{{alertInfo}}</b></div>
                    </div>
                    <button @click.prevent="submit" class="btn-black-full w-100 mt-4 mb-4">Zaktualizuj</button>
                </div>
            </div>
        </div>
        <div class="bg-gray" v-else>
            <Loader/>
        </div>
    </div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
import { Cropper } from 'vue-advanced-cropper'
import Loader from '../components/Loader'

export default {
  name: 'SettingsEditProfile',
  components: {
    TopMenuSubheadline,
    Loader,
    Cropper
    // CircleStencil
  },
  computed: {
    name: {
      get () {
        return this.$store.state.userData.name
      },
      set (value) {
        this.$store.commit('updateUserName', value)
      }
    },
    surname: {
      get () {
        return this.$store.state.userData.surname
      },
      set (value) {
        this.$store.commit('updateUserSurname', value)
      }
    },
    image: {
      get () {
        return this.$store.state.userData.thumb_url
      },
      set (value) {
        this.$store.commit('updateUserThumb', value)
      }
    },
    motto: {
      get () {
        return this.$store.state.userData.motto
      },
      set (value) {
        this.$store.commit('updateUserMotto', value)
      }
    },
    fullname: {
      get () {
        return this.$store.state.userData.fullname
      },
      set (value) {
        if (value.trim() !== '') {
          this.$store.commit('updateUserFullname', value)
        }
      }
    },
    city: {
      get () {
        return this.$store.state.userData.city
      },
      set (value) {
        this.$store.commit('updateUserCity', value)
      }
    },
    email: {
      get () {
        return this.$store.state.userData.email
      },
      set (value) {
        this.$store.commit('updateUserEmail', value)
      }
    }
  },
  created () {
    this.$https('/profile', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(({ data }) => {
        this.name = data.fname
        this.surname = data.lname
        this.email = data.email
        this.fullname = data.username
        this.city = data.city
        this.motto = data.motto
        this.image = data.thumb_url
        this.isLoading = false
      })
      .catch(err => {
        console.log('', err)
      })
  },
  data () {
    return {
      alertInfo: '',
      title: 'Edytuj profil',
      img: '',
      password: '',
      new_password: '',
      new_password_repeat: '',
      isLoading: true,
      isNameDisable: true,
      isSurnameDisable: true,
      isCityDisable: true,
      isMottoDisable: true,
      isEmailDisable: true,
      isPasswordDisable: true,
      editPhoto: false,
      editedImage: null,
      upload: false,
      file: null,
      imageToSend: null,
      coordinates: {},
      defaultPosition: null
    }
  },
  methods: {
    onChange ({ coordinates, canvas }) {
      this.coordinates = coordinates
      if (this.defaultPosition === null) {
        this.defaultPosition = { top: coordinates.top, left: coordinates.left, r: coordinates.width / 2 }
      }
    },
    submit () {
      // const username = this.name.concat(this.surname)
      const newUsername = this.name + ' ' + this.surname
      if (!this.motto.length) {
        this.motto = ' '
      }
      if (this.name.trim().length && this.surname.length && (this.password.trim().length)) {
        if (this.new_password !== this.new_password_repeat) {
          this.alertInfo = 'Hasła muszą być takie same'
        } else {
          if (this.new_password.length < 7) {
            this.alertInfo = 'Hasło musi posiadać co najmniej 6 znaków'
          } else {
            const getData = `fname=${this.name}&lname=${this.surname}&city=${this.city}&motto=${this.motto}&password=${this.password}&new_password=${this.new_password}&new_password_confirmation=${this.new_password_repeat}&username=${newUsername}`
            this.isLoading = true
            this.$https('/profile', {
              method: 'POST',
              mode: 'no-cors',
              crossDomain: true,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              },
              data: getData
            }).then(response => {
              if (response.data.message) {
                this.alertInfo = response.data.message
              } else {
                this.alertInfo = 'Dane zostały zaktualizowane'
                this.isLoading = false
              }
            })
              .catch(error => {
                console.log('', error)
              })
          }
        }
      } else if (this.name.trim().length && this.surname.length && !this.password.trim().length) {
        const newUsername = this.name + ' ' + this.surname
        const getData = `fname=${this.name}&lname=${this.surname}&city=${this.city}&motto=${this.motto}&username=${newUsername}`
        this.isLoading = true
        this.$https('/profile', {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: getData
        }).then(response => {
          this.alertInfo = 'Dane zostały zaktualizowane'
          this.isLoading = false
        })
          .catch(error => {
            console.log('', error)
          })
      }
    },

    loadImage (event) {
      this.editPhoto = true
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.editedImage = e.target.result
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
        this.upload = true
        this.file = input.files[0]
      }
    },

    uploadImage (event) {
      this.isLoading = true
      const formData = new FormData()
      formData.append('img', this.editedImage)
      formData.append('windowWidth', '0')
      formData.append('windowHeight', '0')
      formData.append('width', '0')
      formData.append('height', '0')
      formData.append('posx', (this.defaultPosition.left + this.defaultPosition.r - (this.coordinates.left + this.coordinates.width / 2)) + '')
      formData.append('posy', (this.defaultPosition.top + this.defaultPosition.r - (this.coordinates.top + this.coordinates.width / 2)) + '')
      formData.append('r', (this.coordinates.width / 2) + '')
      formData.append('scale', '1')

      this.$https.post('/update-img', formData, {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
        // data: `img=${this.editedImage}&windowWidth=295&windowHeight=500&width=295&height=433.797&posx=0&posy=0&r=91.45&scale=1`
      }).then(({ data }) => {
        this.image = data.response.thumb_url
        this.editPhoto = false
        this.upload = false
        this.isLoading = false
      })
    }

  }
}
</script>
<style lang="scss" scoped>
  .app-content-box {
    min-height: 100vh;
  }
    .wrapper {
        margin-top: 45px;
    }
    .submit-wrapper {
        margin-bottom: 30px;
    }

    .edit {
        &-input {
            width: 90%;
            height: 120%;
            border: none;

            &:disabled {
                background-color: #fff;
            }
        }
    }

    .upload-example-cropper {
        border: solid 1px #EEE;
        height: 300px;
        width: 100%;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 17px;
    }

    .button {
        color: white;
        font-size: 16px;
        padding: 10px 20px;
        background: #000;
        cursor: pointer;
        transition: background 0.5s;
        font-weight: 100
    }

    .button:hover {
        background: #000;
    }

    .button input {
        display: none;
    }
</style>
